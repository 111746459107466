.dashboard {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.sidebar {
  width: 250px;
  background-color: #2c3e50;
  color: white;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

.sidebarHeader {
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: #f4a940;
}

.sidebarNav {
  flex: 1;
  padding: 20px 0;
}

.navItem {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: white;
  text-decoration: none;
  transition: background-color 0.3s;
  width: 100%;
  justify-content: flex-start;
}

.navItem:hover {
  background-color: rgba(244, 169, 64, 0.1);
}

.navItem span {
  margin-left: 10px;
}

.sidebarFooter {
  padding: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.mainContent {
  flex: 1;
  overflow-y: auto;
  background-color: #f4f6f8;
}

.topBar {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.menuButton {
  display: none;
  margin-right: 15px;
}

.searchBar {
  display: flex;
  align-items: center;
  background-color: #f4f6f8;
  border-radius: 20px;
  padding: 5px 15px;
  flex: 1;
  max-width: 400px;
}

.searchBar input {
  border: none;
  background: transparent;
  margin-left: 10px;
  outline: none;
  width: 100%;
}

.topBarRight {
  display: flex;
  align-items: center;
}

.iconButton {
  margin-left: 10px;
}

.dashboardContent {
  padding: 20px;
}

.pageTitle {
  font-size: 24px;
  margin-bottom: 20px;
  color: #2c3e50;
}

.statsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.statCard {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.statCard h3 {
  font-size: 16px;
  color: #7f8c8d;
  margin-bottom: 10px;
}

.statNumber {
  font-size: 24px;
  font-weight: bold;
  color: #f4a940;
}

.recentActivity {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.recentActivity h3 {
  font-size: 18px;
  margin-bottom: 15px;
  color: #2c3e50;
}

.activityList {
  list-style-type: none;
  padding: 0;
}

.activityList li {
  padding: 10px 0;
  border-bottom: 1px solid #ecf0f1;
}

.activityList li:last-child {
  border-bottom: none;
}

@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    left: -250px;
    height: 100vh;
    z-index: 1000;
  }

  .sidebarOpen {
    left: 0;
  }

  .menuButton {
    display: block;
  }

  .mainContent {
    width: 100%;
  }
}
