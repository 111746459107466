.dropdownMenu {
  position: relative;
  display: inline-block;
}

.dropdownMenuTrigger {
  background-color: #f4a940;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
}

.dropdownMenuTrigger:hover {
  background-color: #e59a30;
}

.dropdownMenuContent {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
}

.dropdownMenuContent.open {
  display: block;
}

.dropdownMenuItem {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
}

.dropdownMenuItem:hover {
  background-color: #f1f1f1;
}
